import { Typography, Button, Space, message, Divider } from 'antd';
import './inspectionCustomer.style.scss';
import { X } from 'react-feather';
import { REJECTION_STATUS } from '../../../../common/utilities/constants';
import { useEffect, useState } from 'react';
import { approveRejection } from '../api';
import { CopyCardBlackIcon } from '../../../../assets/icons/Icons';
import { formatCurrency } from '../../../../utility/utils';

const { Text } = Typography;

const WaitForRejectionModal = ({ data, handleCloseWaitForRejectionModal, refresh }) => {
  const [isFirstStage, setIsFirstStage] = useState(true);
  useEffect(() => {
    setIsFirstStage(data?.rejection_status === null);
  }, [data]);
  let invoiceNumberCount = 0;
  data?.invoices?.map((invoice) => {
    const invoiceNumbers = invoice?.invoice_number_eu?.split(',') || [];
    invoiceNumberCount += invoiceNumbers.length;
  });
  const handleApproveShippedWithoutTaxRefund = async () => {
    try {
      const result = await approveRejection(data.id, {
        status: REJECTION_STATUS.SHIPPED_WITHOUT_TAX_REFUND
      });
      if (result) {
        message.success('success');
        refresh();
      } else {
        message.error('Failed to rejection');
      }
    } catch (error) {
      message.error('Failed to rejection');
    }
    handleCloseWaitForRejectionModal();
  };
  const handleCancelRejection = async () => {
    try {
      if (data?.rejection_status !== REJECTION_STATUS.APPROVED) {
        const result = await approveRejection(data.id, {
          status: REJECTION_STATUS.APPROVED
        });
        if (result) {
          message.success('success');
          refresh();
        } else {
          message.error('Failed to rejection');
        }
      }
    } catch (error) {
      message.error('Failed to rejection');
    }
    handleCloseWaitForRejectionModal();
  };
  const handleConfirmDeposit = async () => {
    try {
      if (data?.rejection_status !== REJECTION_STATUS.DEPOSIT_COMPLETED) {
        const result = await approveRejection(data.id, {
          status: REJECTION_STATUS.DEPOSIT_COMPLETED
        });
        if (result) {
          message.success('success');
          refresh();
        } else {
          message.error('Failed to rejection');
        }
      }
    } catch (error) {
      message.error('Failed to rejection');
    }
    handleCloseWaitForRejectionModal();
  };
  return (
    <>
      {isFirstStage ? (
        <>
          <Space direction="horizontal" className="mb-3" style={{ width: '100%', justifyContent: 'space-between' }}>
            <Text style={{ fontSize: '16px', fontWeight: '700', display: 'block' }}>환급 거절 사유</Text>
            <X size={24} style={{ cursor: 'pointer' }} onClick={handleCloseWaitForRejectionModal} />
          </Space>
          <div style={{ marginBottom: '24px' }}>
            <Text
              style={{
                fontSize: '14px',
                fontWeight: '400',
                color: '#344054',
                backgroundColor: '#F2F4F7',
                padding: '12px',
                borderRadius: '8px',
                display: 'block',
                marginBottom: '8px'
              }}>
              {data?.reject_reason}
            </Text>
            <ul>
              <li>
                <Text style={{ fontSize: '12px', fontWeight: '400', color: '#344054', display: 'block' }}>
                  환급 거절 사유를 확인하시고 아래 버튼을 선택해 주세요.
                </Text>
              </li>
              <li>
                <Text style={{ fontSize: '12px', fontWeight: '400', color: '#344054', display: 'block' }}>
                  반품 요청시 송장 번호당 반품 수수료 7,500원이 청구됩니다.
                </Text>
              </li>
            </ul>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Button
              type="primary"
              size="large"
              block
              onClick={() => setIsFirstStage(false)}
              style={{ backgroundColor: '#F63D68', height: '40px', borderRadius: '5px' }}>
              반품 요청
            </Button>
            <Button
              size="large"
              type="primary"
              style={{ backgroundColor: '#3431D6', height: '40px', borderRadius: '5px' }}
              block
              onClick={handleApproveShippedWithoutTaxRefund}>
              세금 환급 없이 출고
            </Button>
          </div>
        </>
      ) : (
        <>
          <Space direction="horizontal" className="mb-3" style={{ width: '100%', justifyContent: 'space-between' }}>
            <Text style={{ fontSize: '16px', fontWeight: '700', display: 'block' }}>반품 요청</Text>
          </Space>
          <div style={{ marginBottom: '24px' }}>
            <Text
              style={{ fontSize: '14px', fontWeight: '400', color: '#344054', display: 'block', marginBottom: '8px' }}>
              송장 번호당 반품 수수료 7,500원이 청구됩니다.
            </Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Text
                style={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#344054',
                  display: 'block',
                  marginBottom: '8px'
                }}>
                총 반품 수수료
              </Text>
              <Text
                style={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#344054',
                  display: 'block',
                  marginBottom: '8px'
                }}>
                <strong>
                  {invoiceNumberCount}개*7,500원 = {formatCurrency(invoiceNumberCount * 7500, 0)}원
                </strong>
              </Text>
            </div>
            <Text
              style={{ fontSize: '14px', fontWeight: '400', color: '#344054', display: 'block', marginBottom: '8px' }}>
              아래 계좌로 접수 및 반품 수수료를 입금해 주세요.
            </Text>
            <div style={{ backgroundColor: '#F2F4F7', padding: '12px 15px', borderRadius: '8px', marginBottom: '8px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text style={{ fontSize: '14px', fontWeight: '400', color: '#344054', display: 'block' }}>은행명</Text>
                <Text style={{ fontSize: '14px', fontWeight: '400', color: '#344054', display: 'block' }}>
                  국민은행
                </Text>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text style={{ fontSize: '14px', fontWeight: '400', color: '#344054', display: 'block' }}>
                  계좌번호
                </Text>
                <Text
                  style={{ fontSize: '14px', fontWeight: '400', color: '#344054', display: 'block' }}
                  onClick={() => {
                    message.success('Copy Account Number successfully.');
                    navigator.clipboard.writeText('8035010012033');
                  }}>
                  8035010012033 <Button icon={<CopyCardBlackIcon />} size="small" />
                </Text>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text style={{ fontSize: '14px', fontWeight: '400', color: '#344054', display: 'block' }}>예금주</Text>
                <Text style={{ fontSize: '14px', fontWeight: '400', color: '#344054', display: 'block' }}>
                  (주)오드비
                </Text>
              </div>
              <Divider style={{ margin: '8px 0' }} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text
                  style={{ fontSize: '14px', fontWeight: '700', color: '#344054', display: 'block', marginTop: '8px' }}>
                  총 입금액
                </Text>
                <Text
                  style={{ fontSize: '14px', fontWeight: '700', color: '#344054', display: 'block', marginTop: '8px' }}>
                  {formatCurrency(invoiceNumberCount * 7500, 0)}원
                </Text>
              </div>
            </div>
            <Text style={{ fontSize: '12px', fontWeight: '400', color: '#344054', display: 'block' }}>
              * 입금 확인 후 해당 건은 현지 반품 처리됩니다.
            </Text>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
            <Button
              size="large"
              type="default"
              style={{ height: '40px', borderRadius: '5px', flex: 1 }}
              onClick={handleCancelRejection}>
              닫기
            </Button>
            <Button
              size="large"
              type="primary"
              style={{ backgroundColor: '#F63D68', height: '40px', borderRadius: '5px', flex: 1 }}
              onClick={handleConfirmDeposit}>
              입금 완료
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default WaitForRejectionModal;
