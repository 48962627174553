import { Form, Space, Button, Modal, Typography, Col, Row, Input, Checkbox } from 'antd';
import React, { useEffect } from 'react';
import { DeleteOutlined, QuestionCircleOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { NUMBER_KOREAN, COUNTRIES } from '../../../../common/utilities/constants';
import './productCard.style.scss';
import InputEuroCurrency from '../../../../common/components/InputNumber/InputEuroCurrency';
import ProductInfo from './ProductInfo';
import UploadFileCustom from '../../../../common/components/UploadFile';
import { formatCurrency } from '../../../../utility/utils';
import { countBy, isArray } from 'lodash';
const { Text } = Typography;

const InvoiceInfo = ({
  mode = 'edit',
  onValidate,
  role,
  handleValidateProducts,
  country,
  form,
  setHelpInfo,
  refundRate
}) => {
  const currency = COUNTRIES?.[country]?.currency || COUNTRIES.france.currency;
  const invoices = form.getFieldValue('invoices') || [];
  const [invoiceNumberChecked, setInvoiceNumberChecked] = React.useState(
    invoices.map((invoice) => {
      return isArray(invoice.invoice_number_eu) && invoice.invoice_number_eu?.[0] === '직접 배송했어요';
    })
  );
  useEffect(() => {
    setInvoiceNumberChecked(
      invoices.map((invoice) => {
        return isArray(invoice.invoice_number_eu) && invoice.invoice_number_eu?.[0] === '직접 배송했어요';
      })
    );
  }, [invoices]);
  const handleRemove = (remove) => {
    Modal.confirm({
      title: '삭제 하시겠습니까?',
      onCancel: () => {},
      onOk: () => {
        remove();
      },
      centered: true,
      icon: null,
      cancelText: '닫기',
      okText: '삭제',
      okButtonProps: {
        danger: true,
        type: 'primary',
        style: {
          marginRight: '8%',
          width: '40%'
        }
      },
      cancelButtonProps: {
        style: {
          width: '40%'
        }
      }
    });
  };

  const disableDeleteButton = () => {
    const invoices = form.getFieldValue('invoices') || [];
    return invoices.length <= 1;
  };

  const getInvoiceNameCard = (key) => {
    return NUMBER_KOREAN[key] || '';
  };

  const openInvoiceImageHelp = () => {
    setHelpInfo({
      show: true,
      title: '구매 인보이스',
      content: (
        <ul>
          <li>
            이메일 또는 주문 내역 캡처가 아닌{' '}
            <strong style={{ color: 'red' }}>메일 첨부파일 또는 구매 내역 facture로 다운받을 수 있는 PDF 파일</strong>을
            첨부해 주세요
          </li>
          <li>
            인보이스 형태를 주문내역에서 <strong style={{ color: 'red' }}>다운로드 또는 캡쳐</strong> 후 업로드
            해주세요.
            <br />
            (인보이스에는 <strong style={{ color: 'red' }}>FR로 시작하는 판매사의 세금 번호</strong>가 포함되어
            있습니다.)
          </li>
        </ul>
      )
    });
  };

  const getFinalEstRefund = (getFieldValue, invoiceIdx) => {
    const products = getFieldValue(['invoices', invoiceIdx, 'products']) || [];
    const total = _.sumBy(products, 'est_refund_amount') || 0;
    return total;
  };

  const checkInvoiceLength = () => {
    const invoices = form.getFieldValue('invoices');
    if (invoices.length < 10) {
      return true;
    } else {
      Modal.success({
        content: '최대 10개의 인보이스만 추가할 수 있습니다.',
        centered: true,
        icon: null,
        okText: '확인',
        okButtonProps: {
          type: 'default',
          size: 'large',
          style: {
            width: '100%'
          }
        }
      });
    }
    return false;
  };

  return (
    <>
      <Form.List name="invoices" noStyle>
        {(fields, { add, remove }) => (
          <div className="w-100">
            {fields.map((field) => (
              <>
                <div className="py-4 px-3 bg-white mt-3">
                  {/* {field.name === 0 && (
                    <div className="text-14 gray-900 fw-5" style={{ color: 'red', fontWeight: 700 }}>
                      * 에르메스, 셀린느 브랜드는 1회 신청서에 단일 제품, 단일 수량만 신청할 수 있습니다.
                    </div>
                  )} */}
                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <Typography.Title level={4} className="fw-7">
                      {getInvoiceNameCard(field.name)} 번째 인보이스 정보
                    </Typography.Title>
                    <Space>
                      {field.name === 0 && (
                        <>
                          <span style={{ color: 'red' }}>*</span> 필수{' '}
                        </>
                      )}
                      {mode !== 'view' && field.name !== 0 && (
                        <Button
                          style={{ width: '120px' }}
                          icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
                          size="large"
                          onClick={() => handleRemove(() => remove(field.name))}
                          disabled={disableDeleteButton()}
                          key={`deleteBtn${field.key}`}
                          block>
                          삭제
                        </Button>
                      )}
                    </Space>
                  </div>
                  {/* Upload Product Invoice picture */}
                  <Form.Item
                    key={`pictures${field.key}`}
                    name={[field.name, 'pictures']}
                    label={
                      <div
                        className="w-100 d-flex flex-column mt-3"
                        id={`create-refund-request_invoices_${field.key}_pictures_label`}>
                        <Space className="no-width-space">
                          <div className="text-14 gray-900 fw-5">
                            구매 인보이스 <span style={{ color: 'red' }}>*</span>{' '}
                          </div>
                          <Button onClick={openInvoiceImageHelp} style={{ border: '0', padding: '0' }}>
                            <QuestionCircleOutlined />
                          </Button>
                        </Space>
                      </div>
                    }>
                    <>
                      <UploadFileCustom
                        accept=".png,.jpg,.jpeg,.pdf"
                        upload_hint="PDF or JPG or PNG..."
                        isViewOnly={mode === 'view' ? true : false}
                      />
                    </>
                  </Form.Item>
                  {/* EU Invoice Number */}
                  {/* <Form.Item
                    key={`invoice_number_eu${field.key}`}
                    name={[field.name, 'invoice_number_eu']}
                    label={
                      <Text>
                        유럽 내 송장 번호 <span style={{ color: 'red' }}>*</span>
                      </Text>
                    }>
                    <Input
                      size="large"
                      placeholder="유럽 내 송장 번호를 입력해 주세요"
                      disabled={mode == 'view' || invoiceNumberChecked?.[field.name] || false}
                    />
                  </Form.Item> */}
                  <Form.Item
                    // key={`invoice_number_eu${field.key}`}
                    // name={[field.name, 'invoice_number_eu']}
                    label={
                      <Text style={{ marginBottom: '8px' }}>
                        유럽 내 송장 번호 <span style={{ color: 'red' }}>*</span>
                      </Text>
                    }
                    shouldUpdate>
                    {({ getFieldValue }) => {
                      const invoiceNumberEu = getFieldValue(['invoices', field.name, 'invoice_number_eu']);
                      const invoiceNumberEuArray = isArray(invoiceNumberEu)
                        ? invoiceNumberEu
                        : invoiceNumberEu?.split(',') || ['&nbsp;'];
                      return invoiceNumberEuArray?.map((val, index) => (
                        <>
                          <Row
                            justify="space-between"
                            gutter={4}
                            style={index !== invoiceNumberEuArray.length - 1 ? { marginBottom: '8px' } : {}}>
                            <Col style={{ width: 'calc(100% - 48px)' }}>
                              <Input
                                style={{ height: '40px', borderRadius: '5px' }}
                                key={`invoice_number_eu${field.key}${index}`}
                                size="large"
                                value={val.trim().replace('&nbsp;', '')}
                                disabled={mode == 'view' || invoiceNumberChecked?.[field.name] || false}
                                placeholder="유럽 내 송장 번호를 입력해 주세요"
                                onChange={(e) => {
                                  const newInvoiceNumberEu = [...invoiceNumberEuArray];
                                  newInvoiceNumberEu[index] = e.target.value;
                                  form.setFieldValue(['invoices', field.name, 'invoice_number_eu'], newInvoiceNumberEu);
                                }}
                              />
                            </Col>
                            <Col style={{ width: '44px' }}>
                              {index === invoiceNumberEuArray.length - 1 ? (
                                <Button
                                  block
                                  icon={<PlusOutlined style={{ fontSize: '20px', color: '#344054' }} />}
                                  disabled={mode == 'view' || invoiceNumberChecked?.[field.name] || false}
                                  style={{
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: '5px',
                                    borderColor: '#344054',
                                    color: '#344054'
                                  }}
                                  onClick={() => {
                                    if (invoiceNumberEuArray?.length >= 10) {
                                      return;
                                    }
                                    form.setFieldValue(
                                      ['invoices', field.name, 'invoice_number_eu'],
                                      [...invoiceNumberEuArray, '&nbsp;']
                                    );
                                  }}
                                />
                              ) : (
                                <Button
                                  block
                                  icon={<MinusOutlined style={{ fontSize: '20px', color: '#344054' }} />}
                                  style={{
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: '5px',
                                    borderColor: '#344054',
                                    color: '#344054'
                                  }}
                                  onClick={() => {
                                    const newInvoiceNumberEu = [...invoiceNumberEuArray];
                                    newInvoiceNumberEu.splice(index, 1);
                                    form.setFieldValue(
                                      ['invoices', field.name, 'invoice_number_eu'],
                                      newInvoiceNumberEu
                                    );
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        </>
                      ));
                    }}
                  </Form.Item>
                  <Checkbox
                    className="mb-3"
                    checked={invoiceNumberChecked?.[field.name] || false}
                    onClick={() => {
                      const checked = !(invoiceNumberChecked?.[field.name] || false);
                      if (checked) {
                        form.setFieldValue(['invoices', field.name, 'invoice_number_eu'], ['직접 배송했어요']);
                      } else {
                        form.setFieldValue(['invoices', field.name, 'invoice_number_eu'], ['&nbsp;']);
                      }
                      setInvoiceNumberChecked((prev) => {
                        const newInvoiceNumberChecked = [...prev];
                        newInvoiceNumberChecked[field.name] = checked;
                        return newInvoiceNumberChecked;
                      });
                    }}>
                    <div className="text-14 gray-900 fw-5" style={{ color: 'red', fontWeight: '700' }}>
                      직접 배송했어요
                    </div>
                  </Checkbox>
                  {/* Products */}
                  <ProductInfo
                    mode={mode}
                    country={country}
                    onValidate={handleValidateProducts}
                    invoiceIndex={field.name}
                    form={form}
                    role={role}
                    refundRate={refundRate}
                    setHelpInfo={setHelpInfo}
                  />
                  {/* Discount   */}
                  <Form.Item
                    key={`discount${field.key}`}
                    name={[field.name, 'discount']}
                    label={<div className="text-14 gray-900 mt-3 fw-5">할인</div>}>
                    <InputEuroCurrency
                      className="w-100"
                      placeholder={'할인 금액을 입력해 주세요'}
                      prefix={currency?.symbol}
                    />
                  </Form.Item>
                  <span style={{ color: 'red', fontSize: '12px' }}>
                    인보이스상 기재된 할인 금액이 있다면 기재해주세요
                  </span>
                  <div className="py-4 px-3 mt-3" style={{ backgroundColor: '#F2F4F7' }}>
                    <Typography.Title level={4} className="fw-7 ">
                      {getInvoiceNameCard(field.name)} 번째 인보이스
                    </Typography.Title>
                    {/* Total Prices */}
                    <Row justify="space-between">
                      <Col>환급 예상 금액</Col>
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                          const total = getFinalEstRefund(getFieldValue, field.name);
                          return formatCurrency(total, 2) + currency.label;
                        }}
                      </Form.Item>
                    </Row>
                    <Row justify="space-between">
                      <Col>할인 금액</Col>
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                          return (
                            <Text type="danger">
                              -
                              {formatCurrency(getFieldValue(['invoices', field.name, 'discount']) || 0, 2) +
                                currency.label}
                            </Text>
                          );
                        }}
                      </Form.Item>
                    </Row>
                    <Row justify="space-between">
                      <Text strong>환급 예정 금액</Text>
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                          let final = getFinalEstRefund(getFieldValue, field.name);
                          let discount = getFieldValue(['invoices', field.name, 'discount']);
                          if (final < discount) {
                            discount = final;
                            form.setFieldValue(['invoices', field.name, 'discount'], discount);
                          }
                          if (discount) final -= discount;
                          return <Text strong>{formatCurrency(final, 2) + currency.label}</Text>;
                        }}
                      </Form.Item>
                    </Row>
                  </div>
                </div>
              </>
            ))}
            {mode !== 'view' && (
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  return (
                    <div className="w-100 bg-white px-3 pb-3">
                      <Button
                        block
                        type="primary"
                        onClick={() => {
                          if (!checkInvoiceLength()) {
                            return;
                          }
                          add({
                            discount: 0,
                            pictures: [],
                            products: [
                              {
                                product_url: '',
                                product_name: '',
                                product_origin: '',
                                product_option: '',
                                quantity: 1,
                                total_price: 0,
                                unit_price: 0,
                                vat: 0,
                                est_refund_amount: 0,
                                total_price_incl_vat: 0,
                                memo: null
                              }
                            ]
                          });
                        }}
                        disabled={!onValidate(getFieldValue('invoices'))}>
                        인보이스 추가 (최대 10개)
                      </Button>
                    </div>
                  );
                }}
              </Form.Item>
            )}
          </div>
        )}
      </Form.List>
    </>
  );
};

export default InvoiceInfo;
