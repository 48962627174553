import { Input, Form, Select, Checkbox, Space, Typography, Drawer, DatePicker, Col, Row, Button } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';
import { MOBILE_TYPE_OPTIONS, RECIPIENT_COUNTRIES, RECIPIENT_COUNTRIES_OPTIONS } from '../../../../common/utilities/constants';
import TotalForm from './TotalForm';
import { CloseOutlined } from '@ant-design/icons';
import InvoiceInfo from './InvoiceInfo';

const RequestInfo = ({
  mode = 'edit',
  form,
  role,
  handleValidateInvoices,
  handleValidateProducts,
  requestId,
  country,
  refundRate,
  handleCoupon
}) => {
  const [helpInfo, setHelpInfo] = useState({
    show: false,
    title: '',
    content: ''
  });

  const resetHelpInfo = () => {
    setHelpInfo({
      show: false,
      title: '',
      content: ''
    });
  };

  // Watch the 'tax_payment' field
  const taxPayment = Form.useWatch('tax_payment', form);

  return (
    <>
      <div className="container-page" style={{ backgroundColor: '#F2F4F7', padding: '0' }}>
        <div className="mt-3 bg-white px-3">
          {!requestId && mode === 'view' && (
            <div className="text-14 gray-900 fw-5" style={{ color: 'red', fontWeight: 700 }}>
              * 주문서 최종 제출 전, 스크롤을 내려 작성 내용을 확인 후 접수 버튼을 눌러주세요.
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center">
            <Typography.Title level={4} className="fw-7">
              배송 정보
            </Typography.Title>
            <div>
              <span style={{ color: 'red' }}>*</span> 필수
            </div>
          </div>
        </div>
        <div className="py-4 px-3 bg-white">
          <Form.Item
            name="recipient"
            label={
              <div className="w-100 d-flex justify-content-between">
                <div className="text-14 gray-900 fw-5">
                  수취인 <span style={{ color: 'red' }}>*</span>
                </div>
              </div>
            }>
            <Input size="large" placeholder="수취인" />
          </Form.Item>
          <Form.Item name="recipient_en">
            <Input size="large" placeholder="영문 이름" />
          </Form.Item>
          <Form.Item
            name="areaCode"
            label={
              <div className="w-100 d-flex justify-content-between">
                <div className="text-14 gray-900 fw-5">
                  전화 번호 <span style={{ color: 'red' }}>*</span>
                </div>
              </div>
            }>
            <Select size="large" defaultValue="south_korea">
              {MOBILE_TYPE_OPTIONS.map((opt, idx) => (
                <Select.Option key={`mobileTypeOpt${idx}`} value={opt.value} label={opt.label}>
                  <Space>
                    {/* {opt.icon} */}
                    {opt.label}
                  </Space>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="phone"
            className="w-100"
            rules={[
              {
                pattern: /^\d{3}[-]?\d{3,4}[-]?\d{4}$/,
                message: 'Please enter a valid phone number (e.g. 12345678901)'
              }
            ]}>
            <Input size="large" placeholder="전화 번호" className="w-100" />
          </Form.Item>
          <Form.Item
            name="recipient_country"
            label={
              <div className="w-100 d-flex justify-content-between">
                <div className="text-14 gray-900 fw-5">국가</div>
              </div>
            }>
            <Select size="large" defaultValue={RECIPIENT_COUNTRIES.KOREA.value} style={{ height: '40px' }}>
              {RECIPIENT_COUNTRIES_OPTIONS.map((opt, idx) => (
                <Select.Option key={`recipientCountryOpt${idx}`} value={opt.value} label={opt.label}>
                  <Space>
                    {opt.icon}
                    {opt.label}
                  </Space>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="postal_code"
            label={
              <div className="w-100 d-flex justify-content-between">
                <div className="text-14 gray-900 fw-5">
                  우편 번호 <span style={{ color: 'red' }}>*</span>
                </div>
              </div>
            }>
            <Input size="large" placeholder="우편 번호" />
          </Form.Item>
          <Form.Item
            name="address"
            label={
              <div className="w-100 d-flex justify-content-between">
                <div className="text-14 gray-900 fw-5">
                  주소 <span style={{ color: 'red' }}>*</span>
                </div>
              </div>
            }>
            <Input size="large" placeholder="주소" />
          </Form.Item>
          <Form.Item key={`address_en`} name={'address_en'} style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item
            name="tax_id"
            label={
              <div className="w-100 d-flex justify-content-between">
                <div className="text-14 gray-900 fw-5">
                  개인 통관부호 또는 사업자 통관번호 <span style={{ color: 'red' }}>*</span>
                </div>
              </div>
            }>
            <Input size="large" placeholder="개인 통관부호 또는 사업자 통관번호" />
          </Form.Item>
          <a href="https://unipass.customs.go.kr/csp/persIndex.do" target="_blank" rel="noopener noreferrer">
            <p>개인 통관 부호 조회/발급</p>
          </a>
          <Form.Item name="collect_customs" valuePropName="checked">
            <Checkbox size="large">
              <div className="text-14 gray-900 fw-5">
                통관 정보 수집 및 제공 동의 <span style={{ color: 'red' }}>*</span>
              </div>
            </Checkbox>
          </Form.Item>
          <div className="text-14 gray-900 fw-5">한국 통관 세금 납부 여부</div>
          <Form.Item
            name="tax_payment"
            valuePropName="checked"
            style={{
              padding: '8px 12px',
              border: taxPayment ? '1px solid #3431D6' : '1px solid #D0D5DD',
              backgroundColor: taxPayment ? '#F8F9FC' : '#FFFFFF',
              borderRadius: '5px'
            }}>
            <Checkbox size="large">
              <div className="text-14 fw-5" style={{ color: taxPayment ? '#344054' : '#98A2B3' }}>
                수취인 납부
              </div>
            </Checkbox>
          </Form.Item>
          {/* <div className="text-14 gray-900 fw-5" style={{ color: 'red', fontWeight: 700 }}>
            배송 대행지의 한국행 출고 정보를 입력해 주세요
          </div> */}
          {/* <Form.Item
            name="delivery_agency"
            label={
              <div className="w-100 d-flex justify-content-between">
                <div className="text-14 gray-900 fw-5">
                  배송대행지 <span style={{ color: 'red' }}>*</span>
                </div>
              </div>
            }>
            <Input size="large" placeholder="배송대행지" />
          </Form.Item> */}
          {/* Tracking Number */}
          {/* <Form.Item
            name="tracking_number"
            label={
              <div className="w-100 d-flex justify-content-between">
                <div className="text-14 gray-900 fw-5">
                  배송대행지 신청 번호
                </div>
              </div>
            }
            >
            <Input size="large" placeholder="배대지 신청 번호를 입력해 주세요" />
          </Form.Item> */}
          {/* Delivery Date */}
          {/* <Form.Item
            name="delivery_date"
            label={
              <div className="w-100 d-flex justify-content-between">
                <div className="text-14 gray-900 fw-5">출고일</div>
              </div>
            }>
            <DatePicker size="large" placeholder="출고일" style={{ width: '100%' }} />
          </Form.Item> */}
          <Form.Item
            name="memo"
            label={
              <div className="w-100 d-flex justify-content-between">
                <div className="text-14 gray-900 fw-5">메모</div>
              </div>
            }>
            <TextArea maxLength={200} style={{ height: 80, resize: 'none' }} placeholder="메모를 작성해 주세요" />
          </Form.Item>
        </div>
        <InvoiceInfo
          mode={mode}
          form={form}
          country={country}
          role={role}
          onValidate={handleValidateInvoices}
          handleValidateProducts={handleValidateProducts}
          setHelpInfo={setHelpInfo}
          refundRate={refundRate}
        />
        <TotalForm
          form={form}
          country={country}
          mode={mode}
          role={role}
          setHelpInfo={setHelpInfo}
          handleCoupon={handleCoupon}
        />
      </div>
      <Drawer
        // title={'돌려받을 금액'}
        title={helpInfo.title}
        closable={false}
        placement={'bottom'}
        onClose={resetHelpInfo}
        open={helpInfo.show}
        rootClassName="HelpInfoDrawer"
        extra={<Button disabled={false} type="text" icon={<CloseOutlined />} onClick={resetHelpInfo} />}
        headerStyle={{ padding: 0, fontSize: '18px', border: 'none' }}
        bodyStyle={{ padding: '24px 0 0 0', fontSize: '14px', lineHeight: '24px', fontWeight: 400 }}
        contentWrapperStyle={{ height: 'auto' }}
        style={{ borderRadius: '12px 12px 0 0', padding: '24px 16px 41px 16px' }}>
        {helpInfo.content}
      </Drawer>
    </>
  );
};

export default RequestInfo;
