import { Typography, Space, Image, Select, Drawer, Button } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import HeaderFT from '../../../common/layout/HeaderFT';
import refundDestination from '../../../assets/images/refundDestination.svg';
import { ChevronDown } from 'react-feather';
import { COUNTRIES, USER_ROLE } from '../../../common/utilities/constants';
import { useSelector } from 'react-redux';
const { Text, Title } = Typography;

const RefundDestinationPage = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const role = userInfo?.role || USER_ROLE.FreeTBusiness;
  const [country, setCountry] = React.useState(COUNTRIES.france.value);
  const [openNoticeModal, setOpenNoticeModal] = React.useState(false);
  const BILLING_ADDRESS = {
    [USER_ROLE.FreeTBusiness]: {
      [COUNTRIES.spain.value]: {
        title: 'ZappTax',
        name: '성 이름',
        address1: 'Bravo Murillo, 52 1-3',
        address2: '<strong style="color: red">ZappTax</strong>',
        postal: '28003',
        city: 'MADRID',
        number: 'N0173919B'
      },
      [COUNTRIES.france.value]: {
        title: 'vatcat',
        name: '성 이름',
        address1: '61 Cours de la Liberte',
        address2: '<strong style="color: red">vatcat</strong>',
        postal: '69003',
        city: 'Lyon',
        number: '+33615957737'
      },
      [COUNTRIES.uk.value]: {
        title: 'wecat ltd',
        name: '성 이름',
        company: '<strong style="color: red">wecat ltd</strong>',
        address1: '1 old street',
        address2: 'white collar factory',
        postal: 'EC1Y 8AF',
        country: 'United Kingdom',
        city: 'London'
      }
    },
    [USER_ROLE.FreeTCustomer]: {
      [COUNTRIES.spain.value]: {
        name: '성 이름',
        address1: 'Bravo Murillo, 52 1-3',
        address2: 'ZappTax',
        postal: '28003',
        city: 'MADRID',
        number: 'N0173919B'
      },
      [COUNTRIES.france.value]: {
        title: 'vatcat',
        name: '성 이름',
        address1: '61 Cours de la Liberte',
        address2: '<strong style="color: red">vatcat</strong>',
        postal: '69003',
        city: 'Lyon Frace',
        number: '+33615957737'
      },
      [COUNTRIES.uk.value]: {
        name: '성 이름',
        company: 'wecat ltd',
        address1: '1 old street',
        address2: 'white collar factory',
        postal: 'EC1Y 8AF',
        country: 'United Kingdom',
        city: 'London'
      }
    }
  };

  const DELIVERY_ADDRESS = {
    [USER_ROLE.FreeTBusiness]: {
      [COUNTRIES.france.value]: {
        name: '이름 또는 계약사업자명 + <strong style="color: red">ACI Freet</strong>',
        address1: ' 50 Rue des chardonnerets',
        address2: '<strong style="color: red">LOT 14 ACI Freet</strong>',
        postal: '93290',
        city: 'Tremblay en France',
        number: '+33(0)7 72 06 50 73'
      },
      [COUNTRIES.spain.value]: {
        name: '이름 또는 계약사업자명 + <strong style="color: red">ACI Freet</strong>',
        address1: 'Calle Mahon, 6, Bajo B KS HUB MADRID Las Rozas',
        address2: '<strong style="color: red">ACI Freet</strong>',
        postal: '28290',
        city: 'Las Rozas, Madrid',
        number: '912 511 606',
        phone: '647 319 635'
      }
    },
    [USER_ROLE.FreeTCustomer]: {
      [COUNTRIES.france.value]: {
        name: '본인이름 + <strong style="color: red">ACI Freet</strong>',
        address1: ' 50 Rue des chardonnerets',
        address2: '<strong style="color: red">LOT 14 ACI Freet</strong>',
        postal: '93290',
        city: 'Tremblay en France',
        number: '+33(0)7 72 06 50 73'
      },
      [COUNTRIES.spain.value]: {
        name: '본인이름 + <strong style="color: red">ACI Freet</strong>',
        address1: 'Calle Mahon, 6, Bajo B KS HUB MADRID Las Rozas',
        address2: '<strong style="color: red">ACI Freet</strong>',
        postal: '28290',
        city: 'Las Rozas, Madrid',
        number: '912 511 606',
        phone: '647 319 635'
      }
    }
  };
  const billingAddress = BILLING_ADDRESS?.[role]?.[country];
  const deliveryAddress = DELIVERY_ADDRESS?.[role]?.[country];
  const countries_options = Object.values(COUNTRIES).map((c) => ({
    value: c.value,
    label: (
      <div>
        <Space>
          <div style={{ lineHeight: '0' }}>{c.icon}</div>
          <Text>{c.label}</Text>
        </Space>
      </div>
    )
  }));
  return (
    <>
      <HeaderFT
        title={'환급 및 배송 주소'}
        rightIems={
          <Button size="large" style={{ width: '140px' }} onClick={() => setOpenNoticeModal(true)}>
            이미지 예시
          </Button>
        }
      />
      <Space direction="vertical" style={{ backgroundColor: '#F2F4F7', marginBottom: '60px' }} size={'middle'}>
        <Content style={{ backgroundColor: 'white', padding: '32px 16px' }}>
          <div
            style={{
              backgroundColor: '#F2F4F7',
              padding: '12px',
              borderRadius: '4px',
              border: '1px solid #F63D68',
              width: '100%',
              backgroundColor: '#FFF5F6',
              marginBottom: '22px'
            }}>
            <ul style={{ fontSize: '12px', fontWeight: '700', marginBottom: '0px' }}>
              <li>
                <Text>온라인 사이트에서 제품 구매 전, 지정된 아래 주소로 입력 후 결제 진행이 반드시 필요합니다.</Text>
              </li>
              <li>
                <Text>청구 주소지와 배송 주소지가 다르니 한 번 더 유의해 주세요.</Text>
              </li>
            </ul>
          </div>
          <Title level={5} style={{ fontSize: '14px' }} type="danger">
            {'* 청구지 및 배송지 주소를 반드시 아래 주소로 기입해 주세요'}
          </Title>
          <Select
            value={country}
            onChange={(value) => setCountry(value)}
            style={{
              width: '100%',
              marginBottom: '22px',
              border: '1px solid #D0D5DD',
              borderRadius: '5px'
            }}
            suffixIcon={<ChevronDown />}
            bordered={false}
            size="large"
            options={countries_options}
          />
          <Title level={5}>
            {"Facturation L'adress"}
            <br />
            (청구지 주소 - Billing address)
          </Title>
          <div style={{ width: '100%', border: '1px solid #D0D5DD', borderRadius: '4px' }}>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <th style={{ backgroundColor: '#D0D5DD', padding: '12px' }} colSpan={2}>
                    주소 또는 이름 칸에 <strong style={{ color: 'red' }}>{billingAddress?.title || 'izivat'}</strong>가
                    기재되어야 합니다
                  </th>
                </tr>
                <tr style={{ borderBottom: '1px solid #D0D5DD' }}>
                  <td width={'50%'} style={{ backgroundColor: '#F2F4F7', padding: '12px' }}>
                    <strong>Name/Sur Name</strong>
                  </td>
                  <td width={'50%'} style={{ padding: '12px' }}>
                    {billingAddress?.name}
                  </td>
                </tr>
                {billingAddress?.company && (
                  <tr style={{ borderBottom: '1px solid #D0D5DD' }}>
                    <td width={'50%'} style={{ backgroundColor: '#F2F4F7', padding: '12px' }}>
                      <strong>Company</strong>
                    </td>
                    <td width={'50%'} style={{ padding: '12px' }}>
                      <div dangerouslySetInnerHTML={{ __html: billingAddress?.company }} />
                    </td>
                  </tr>
                )}
                <tr style={{ borderBottom: '1px solid #D0D5DD' }}>
                  <td width={'50%'} style={{ backgroundColor: '#F2F4F7', padding: '12px' }}>
                    <strong>Address 1</strong>
                  </td>
                  <td width={'50%'} style={{ padding: '12px' }}>
                    {billingAddress?.address1}
                  </td>
                </tr>
                <tr style={{ borderBottom: '1px solid #D0D5DD' }}>
                  <td width={'50%'} style={{ backgroundColor: '#F2F4F7', padding: '12px' }}>
                    <strong>Address 2</strong>
                  </td>
                  <td width={'50%'} style={{ padding: '12px' }}>
                    <div dangerouslySetInnerHTML={{ __html: billingAddress?.address2 }} />
                  </td>
                </tr>
                <tr style={{ borderBottom: '1px solid #D0D5DD' }}>
                  <td width={'50%'} style={{ backgroundColor: '#F2F4F7', padding: '12px' }}>
                    <strong>Postal (Zip Code)</strong>
                  </td>
                  <td width={'50%'} style={{ padding: '12px' }}>
                    {billingAddress?.postal}
                  </td>
                </tr>
                {billingAddress?.country && (
                  <tr style={{ borderBottom: '1px solid #D0D5DD' }}>
                    <td width={'50%'} style={{ backgroundColor: '#F2F4F7', padding: '12px' }}>
                      <strong>Country</strong>
                    </td>
                    <td width={'50%'} style={{ padding: '12px' }}>
                      {billingAddress?.country}
                    </td>
                  </tr>
                )}
                <tr style={billingAddress?.number ? { borderBottom: '1px solid #D0D5DD' } : {}}>
                  <td width={'50%'} style={{ backgroundColor: '#F2F4F7', padding: '12px' }}>
                    <strong>City</strong>
                  </td>
                  <td width={'50%'} style={{ padding: '12px' }}>
                    {billingAddress?.city}
                  </td>
                </tr>
                {billingAddress?.number && (
                  <tr>
                    <td width={'50%'} style={{ backgroundColor: '#F2F4F7', padding: '12px' }}>
                      <strong>Number</strong>
                    </td>
                    <td width={'50%'} style={{ padding: '12px' }}>
                      {billingAddress?.number}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {deliveryAddress && (
            <>
              <Title level={5} className="mt-3">
                {'Livrasion l’adress'}
                <br />
                (배송지 주소 - Delivery address)
              </Title>
              <div style={{ width: '100%', border: '1px solid #D0D5DD', borderRadius: '4px' }}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th style={{ backgroundColor: '#D0D5DD', padding: '12px' }} colSpan={2}>
                        주소 또는 이름 칸에 <strong style={{ color: 'red' }}>ACI Freet</strong>가 기재되어야 합니다
                      </th>
                    </tr>
                    <tr style={{ borderBottom: '1px solid #D0D5DD' }}>
                      <td width={'50%'} style={{ backgroundColor: '#F2F4F7', padding: '12px' }}>
                        <strong>Name/Sur Name</strong>
                      </td>
                      <td width={'50%'} style={{ padding: '12px' }}>
                        <div dangerouslySetInnerHTML={{ __html: deliveryAddress?.name }} />
                      </td>
                    </tr>
                    <tr style={{ borderBottom: '1px solid #D0D5DD' }}>
                      <td width={'50%'} style={{ backgroundColor: '#F2F4F7', padding: '12px' }}>
                        <strong>Address 1</strong>
                      </td>
                      <td width={'50%'} style={{ padding: '12px' }}>
                        {deliveryAddress?.address1}
                      </td>
                    </tr>
                    <tr style={{ borderBottom: '1px solid #D0D5DD' }}>
                      <td width={'50%'} style={{ backgroundColor: '#F2F4F7', padding: '12px' }}>
                        <strong>Address 2</strong>
                      </td>
                      <td width={'50%'} style={{ padding: '12px' }}>
                        <div dangerouslySetInnerHTML={{ __html: deliveryAddress?.address2 }} />
                      </td>
                    </tr>
                    {deliveryAddress?.postal && (
                      <tr style={{ borderBottom: '1px solid #D0D5DD' }}>
                        <td width={'50%'} style={{ backgroundColor: '#F2F4F7', padding: '12px' }}>
                          <strong>Postal (Zip Code)</strong>
                        </td>
                        <td width={'50%'} style={{ padding: '12px' }}>
                          {deliveryAddress?.postal}
                        </td>
                      </tr>
                    )}
                    <tr style={{ borderBottom: '1px solid #D0D5DD' }}>
                      <td width={'50%'} style={{ backgroundColor: '#F2F4F7', padding: '12px' }}>
                        <strong>City</strong>
                      </td>
                      <td width={'50%'} style={{ padding: '12px' }}>
                        {deliveryAddress?.city}
                      </td>
                    </tr>
                    {deliveryAddress?.number && (
                      <tr style={deliveryAddress?.phone ? { borderBottom: '1px solid #D0D5DD' } : {}}>
                        <td width={'50%'} style={{ backgroundColor: '#F2F4F7', padding: '12px' }}>
                          <strong>Number</strong>
                        </td>
                        <td width={'50%'} style={{ padding: '12px' }}>
                          {deliveryAddress?.number}
                        </td>
                      </tr>
                    )}
                    {deliveryAddress?.phone && (
                      <tr>
                        <td width={'50%'} style={{ backgroundColor: '#F2F4F7', padding: '12px' }}>
                          <strong>Cell Phone</strong>
                        </td>
                        <td width={'50%'} style={{ padding: '12px' }}>
                          {deliveryAddress?.phone}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </Content>
        <Drawer
          title={'이미지 예시'}
          placement={'bottom'}
          onClose={() => setOpenNoticeModal(false)}
          open={openNoticeModal}
          rootClassName="optiosDrawer"
          height="auto"
          headerStyle={{
            borderBottom: '0px',
            padding: '12px 24px'
          }}
          style={{
            borderRadius: '12px 12px 0px 0px'
          }}>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Image
              // width="100%"
              style={{
                // maxWidth: '420px'
                maxWidth: '720px'
              }}
              src={refundDestination}
              preview={{ mask: null }}
            />
          </div>
        </Drawer>
      </Space>
    </>
  );
};

export default RefundDestinationPage;
