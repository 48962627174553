// BottomNavigation.js
import React, { useState } from 'react';
import { TabBar } from 'antd-mobile';
import Icon, { HomeOutlined, FormOutlined, SmileOutlined } from '@ant-design/icons';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Drawer, Typography, Button, Space, Checkbox, Divider, Modal, Select } from 'antd';
import { COUNTRIES, USER_ROLE } from '../../utilities/constants';
import { RefundIcon } from '../../../assets/icons/Icons';
import './style.scss';
import { useSelector } from 'react-redux';
import { ChevronDown } from 'react-feather';
const { Text } = Typography;

// Add this function to handle body scroll
const disableBodyScroll = () => {
  document.body.style.overflow = 'hidden';
};

const enableBodyScroll = () => {
  document.body.style.overflow = '';
};

const BottomNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openCreateRequestModal, setOpenCreateRequestModal] = useState(false);
  const [checkedPolicy, setCheckedPolicy] = useState(false);
  const [country, setCountry] = useState(COUNTRIES.france);
  const { userInfo } = useSelector((state) => state.auth);
  const countries_options = Object.values(COUNTRIES).map((country) => ({
    label: (
      <>
        <Space>
          <div style={{ lineHeight: '0' }}>{country.icon}</div>
          <Text>{country.label}</Text>
          <Text style={{ margin: '0px -5px', color: '#D0D5DD' }}>|</Text>
          {country.disabled ? (
            <Text className="pt-1">
              <strong style={{ color: 'red' }}>서비스 준비중이에요</strong>
            </Text>
          ) : (
            <Text className="pt-1" style={{ marginLeft: '-5px' }}>
              {`부가세 ${country.vat_rate * 100}% 중`}{' '}
              <strong style={{ color: 'red' }}>{country.refund_rate * 100 + '%'}</strong> {`환급`}
            </Text>
          )}
        </Space>
      </>
    ),
    value: country.value,
    disabled: country.disabled
  }));
  const tabs = [
    {
      key: '/refund-request',
      title: '홈',
      icon: <HomeOutlined />
    },
    {
      key: '/create-refund-request',
      title: '환급신청',
      icon: <FormOutlined />
    },
    {
      key: '/refund',
      title: '환급금',
      icon: <RefundIcon color={location.pathname === '/refund' ? '#3431D6' : '#98A2B3'} />
    },
    {
      key: '/profile',
      title: '내정보',
      icon: <SmileOutlined />
    }
  ];

  return (
    <>
      <TabBar
        activeKey={location.pathname}
        onChange={(key) => {
          if (location.pathname === '/create-refund-request') {
            Modal.confirm({
              icon: null,
              title: '사이트에서 나가시겠습니까?',
              content: '변경사항이 저장되지 않을 수 있습니다.',
              centered: true,
              onOk: () => {
                navigate(key);
              },
              okText: '나가기',
              cancelText: '취소',
              okButtonProps: {
                // danger: true,
                // type: 'primary',
                size: 'large',
                style: {
                  // marginRight: '8%',
                  width: '48%'
                  // backgroundColor: '#f63d68'
                }
              },
              cancelButtonProps: {
                style: {
                  width: '48%'
                },
                size: 'large'
              }
            });
            return;
          }
          if (key === '/create-refund-request') {
            disableBodyScroll();
            setOpenCreateRequestModal(true);
          } else {
            navigate(key);
          }
        }}
        style={{
          position: 'fixed',
          // zIndex: 9999,
          bottom: 0,
          width: '100%',
          // maxWidth: '420px',
          maxWidth: '720px',
          marginLeft: '-1px',
          backgroundColor: '#ffffff',
          padding: '8px 0px',
          border: '1px solid #D0D5DD',
          filter: 'drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.16))',
          height: '60px'
        }}>
        {tabs.map((tab) => (
          <TabBar.Item
            key={tab.key}
            icon={tab.icon}
            title={tab.title}
            style={{
              color: location.pathname === tab.key ? '#3431D6' : '#98A2B3'
            }}
          />
        ))}
      </TabBar>
      {/* Choose Refund country and then navigate to create refund request page*/}
      <Drawer
        title={'환급 신청할 국가를 선택해 주세요'}
        placement={'bottom'}
        onClose={() => {
          enableBodyScroll();
          setOpenCreateRequestModal(false);
        }}
        open={openCreateRequestModal}
        rootClassName="optiosDrawer"
        height={
          /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ||
          /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
            ? 'calc(100vh - 84px)'
            : '100vh'
        }
        headerStyle={{
          borderBottom: '0px',
          padding: '12px 24px',
          height: '48px'
        }}
        bodyStyle={{
          height: 'calc(100vh - 48px)'
        }}
        style={{
          // height: '100vh',
          // borderRadius: '12px 12px 0px 0px',
          // maxHeight: 'calc(100vh - 48px)',
          // minHeight: 'calc(70vh - 48px)',
          // overflow: 'unset',
          zIndex: 1000 // Ensure the modal is above other elements
        }}>
        <div className="scrollable-content">
          <Select
            value={country?.value}
            onChange={(value) => {
              setCountry(COUNTRIES?.[value] || COUNTRIES.france);
            }}
            style={{
              width: '100%',
              marginBottom: '10px',
              border: '1px solid #D0D5DD',
              borderRadius: '5px'
            }}
            suffixIcon={<ChevronDown />}
            bordered={false}
            size="large"
            options={countries_options}
            optionLabelProp="label" // Ensure the correct label is displayed after selection
          />
          <div>
            <Text style={{ fontSize: 14, fontWeight: 700 }}>[신청서 작성 시 유의 사항]</Text>
            {country?.value === COUNTRIES?.france?.value ? (
              <ol>
                <li>
                  <Text style={{ display: 'block' }}>
                    제품구매 시 Billing address는 지정 세금 환급처 주소로 기재해야 하며, Delivery address는 배송 센터로
                    기재해야 합니다.
                  </Text>
                  <Text>
                    해당 주소는{' '}
                    <Link to="/refund-destination" target="_blank">
                      [환급 및 배송 주소]
                    </Link>
                    에서 확인 가능합니다.
                  </Text>
                </li>
                <li>
                  <Text>
                    하나의 인보이스에 기재된 상품을 분할해서 배송할 수 없으며, 인보이스상에 확인되는 제품 모두 신청서에
                    작성해야 합니다. (내용이 누락될 경우 해당 신청서는 반려 처리가 됩니다.)
                  </Text>
                </li>
                <li>
                  <Text>인보이스에 기재된 상품 외 다른 상품은 절대 포함시키지 않습니다.</Text>
                </li>
                <li>
                  <Text style={{ display: 'block' }}>
                    {userInfo.role === USER_ROLE.FreeTCustomer ? (
                      <>
                        배송료는 환급 예정 금액에서 차감됩니다. <br />
                        (상세 내역은 [환급금]에서 확인 가능합니다.)
                      </>
                    ) : (
                      '배송료는 물류사에서 직접 청구됩니다.'
                    )}
                  </Text>
                </li>
                <li>
                  <Text style={{ display: 'block' }}>
                    신청서 검수 시 내용이 적격하지 않을 경우 해당 신청서는 반려 처리되며, 반려 처리된 신청서의 물류는
                    100% 반품 처리로 진행됩니다. (반품비용은 따로 청구됩니다.)
                  </Text>
                </li>
                <li>
                  <Text style={{ display: 'block' }}>
                    모든 제품은 기본적으로 검수없이 수령 된 상태 그대로 발송됩니다. 검수가 필요할 시 부가서비스를
                    이용해주세요.
                  </Text>
                </li>
              </ol>
            ) : country?.value === COUNTRIES?.spain?.value ? (
              <ol>
                <li>
                  <Text style={{ display: 'block' }}>
                    현재 스페인 국가는 배송 대행이 연결되어 있지 않습니다.독자적인 배송과 수출신고 자료가 필요합니다.
                  </Text>
                  <Text style={{ display: 'block' }}>
                    신청서 작성 전{' '}
                    <Link to="https://hautevie.notion.site/FAQ-d795297ce98142d2a25ee870b1d41d48" target="_blank">
                      [유저가이드]
                    </Link>{' '}
                    에서 확인해 주세요.
                  </Text>
                </li>
                <li>
                  <Text style={{ display: 'block' }}>
                    인보이스에 기재된 상품 외 다른 상품은 절대 포함하지 않으며, 인보이스상에 확인되는 제품 모두 신청서에
                    작성해야 합니다.
                  </Text>
                  <Text style={{ display: 'block' }}>(내용이 누락될 경우 해당 신청서는 반려 처리가 됩니다.)</Text>
                </li>
                <li>
                  <Text>
                    문의 사항은 <a href="mailto:no-reply@free-t.co.kr">no-reply@free-t.co.kr</a>로 문의 부탁드립니다.
                  </Text>
                </li>
              </ol>
            ) : (
              <ol>
                <li>
                  <Text style={{ display: 'block' }}>
                    현재 영국 국가는 배송 대행이 연결되어 있지 않습니다.독자적인 배송과 수출신고 자료가 필요합니다.
                  </Text>
                  <Text style={{ display: 'block' }}>
                    신청서 작성 전{' '}
                    <Link to="https://hautevie.notion.site/FAQ-d795297ce98142d2a25ee870b1d41d48" target="_blank">
                      [유저가이드]
                    </Link>{' '}
                    에서 확인해 주세요.
                  </Text>
                </li>
                <li>
                  <Text style={{ display: 'block' }}>
                    인보이스에 기재된 상품 외 다른 상품은 절대 포함하지 않으며, 인보이스상에 확인되는 제품 모두 신청서에
                    작성해야 합니다
                  </Text>
                  <Text style={{ display: 'block' }}>(내용이 누락될 경우 해당 신청서는 반려 처리가 됩니다.)</Text>
                </li>
                <li>
                  <Text>
                    문의 사항은 <a href="mailto:no-reply@free-t.co.kr">no-reply@free-t.co.kr</a>로 문의 부탁드립니다.
                  </Text>
                </li>
              </ol>
            )}
          </div>

          <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            onClick={() => setCheckedPolicy(!checkedPolicy)}>
            <Text style={{ fontSize: 12, fontWeight: 400, color: '#F63D68' }}>
              주의 사항을 전부 확인하였으며, 위 사항에 동의합니다.
            </Text>
            <Checkbox checked={checkedPolicy} onChange={(e) => setCheckedPolicy(e.target.checked)} />
          </div>
          <Divider style={{ marginBottom: '10px' }} />
          {/* {Object.values(COUNTRIES)?.map((country, idx) => (
            <Button
              className="w-100 mb-3 p-2"
              style={{ borderRadius: '5px', height: '100%' }}
              hoverable={false}
              key={`country${idx}`}
              onClick={() => {
                enableBodyScroll();
                setOpenCreateRequestModal(false);
                navigate(`/create-refund-request?country=${country.value}`);
              }}
              disabled={country.disabled || !checkedPolicy}>
              <div className="d-flex justify-content-between w-100">
                <Space>
                  <div style={{ lineHeight: '0' }}>{country.icon}</div>
                  <Text>{country.label}</Text>
                </Space>
                {country.disabled ? (
                  <Text className="pt-1">
                    <strong style={{ color: 'red' }}>서비스 준비중이에요</strong>
                  </Text>
                ) : (
                  <Text className="pt-1">
                    {`부가세 ${country.vat_rate * 100}% 중`}{' '}
                    <strong style={{ color: 'red' }}>{country.refund_rate * 100 + '%'}</strong> {`환급`}
                  </Text>
                )}
              </div>
            </Button>
          ))} */}
          <Button
            className="w-100 mb-3 p-2"
            style={Object.assign(
              { borderRadius: '5px', height: '48px' },
              country.disabled || !checkedPolicy ? {} : { backgroundColor: '#3431D6', color: '#ffffff' }
            )}
            disabled={country.disabled || !checkedPolicy}
            onClick={() => {
              enableBodyScroll();
              setOpenCreateRequestModal(false);
              navigate(`/create-refund-request?country=${country.value}`);
            }}>
            신청서 접수
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default BottomNavigation;
