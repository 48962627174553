import { forEach } from 'lodash';
import apiClient from '../../../services/axios';

export const fetchRefundRequest = async (data, thunkApi) => {
  const params = {};
  forEach(data, (value, key) => {
    if (key === 'status') {
      if (value !== 'all') {
        params[key] = value;
      }
    } else if (value) {
      params[key] = value;
    }
  });
  try {
    const response = await apiClient.get('/refund-request', {
      params
    });
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};

export const fetchRefundRequestStatus = async (data, thunkApi) => {
  const params = {};
  forEach(data, (value, key) => {
    if (key === 'status') {
      if (value !== 'all') {
        params[key] = value;
      }
    } else if (value) {
      params[key] = value;
    }
  });
  try {
    const response = await apiClient.get('/refund-request/agg/status', {
      params
    });
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};

export const fetchDefaultRecipient = async (thunkApi) => {
  try {
    const response = await apiClient.get('/refund-request/default-recipient');
    if (response.status === 200) {
      return response.data;
    }
    return thunkApi.rejectWithValue(response);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
};

export const inspectionCustomer = async (id, payload) => {
  try {
    const response = await apiClient.post(`/refund-request/${id}/inspection-customer`, payload);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const approveRejection = async (id, payload) => {
  try {
    const response = await apiClient.post(`/refund-request/${id}/customer-rejection`, payload);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (e) {
    return null;
  }
};
