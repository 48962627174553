import { Button, Col, Drawer, Row, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import HeaderFT from '../../../common/layout/HeaderFT';
import { formatCurrency } from '../../../utility/utils';
const { Title, Text } = Typography;
const ShippingCostPage = () => {
  const [openNoticeModal, setOpenNoticeModal] = React.useState(false);
  const data = [];
  let stepCost = 0;
  for (let i = 0.5; i <= 20; i += 0.5) {
    data.push({
      weight: `${i}kg`,
      cost: `${formatCurrency(11.8 + stepCost, 1)}유로`
    });
    stepCost += 2.3;
  }
  return (
    <>
      <HeaderFT
        title={'배송비'}
        rightIems={
          <Button size="large" style={{ width: '140px' }} onClick={() => setOpenNoticeModal(true)}>
            무게 측정 방법
          </Button>
        }
      />
      <Content className="p-3">
        <Title
          level={5}
          style={{
            fontSize: '16px',
            fontWeight: '500'
          }}>
          배송비는 <strong style={{ color: 'red' }}>예상 환급액에서 자동 차감</strong>되고, 배송비가 환급액을 넘어가는
          경우에만 결제 안내됩니다.
        </Title>
        <div
          style={{
            textAlign: 'center',
            border: '1px solid #dfdfdf',
            borderRadius: '5px 5px 5px 5px',
            marginTop: '15px',
            marginBottom: '50px'
          }}>
          <Row
            className="p-2"
            style={{
              backgroundColor: '#10264D',
              borderRadius: '5px 5px 0 0',
              color: '#fff',
              fontWeight: '700'
            }}>
            <Col span={12}>무게</Col>
            <Col span={12}>운임료</Col>
          </Row>
          {data?.map((i, idx) => (
            <Row
              className="p-2"
              key={`sc${idx}`}
              style={{ fontWeight: '500', backgroundColor: idx % 2 ? '#F2F4F7' : '#fff' }}>
              <Col span={12}>{i.weight}</Col>
              <Col span={12}>{i.cost}</Col>
            </Row>
          ))}
        </div>
      </Content>
      <Drawer
        title={'무게 측정 방법'}
        placement={'bottom'}
        onClose={() => setOpenNoticeModal(false)}
        open={openNoticeModal}
        rootClassName="optiosDrawer"
        height="auto"
        headerStyle={{
          borderBottom: '0px',
          padding: '12px 24px'
        }}
        style={{
          borderRadius: '12px 12px 0px 0px'
        }}>
        <Text
          style={{
            fontSize: '16px',
            fontWeight: '500'
          }}>
          <strong style={{ fontWeight: '700' }}>{'항공사에 따른 부피 무게 측정 방법:'}</strong> <br />{' '}
          {'가로 x 세로 x 높이 cm / 6000 실질 무게와 부피 무게 중 큰 무게로 측정됩니다.'}
        </Text>
      </Drawer>
    </>
  );
};

export default ShippingCostPage;
